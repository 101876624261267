@import "../../helpers/global.scss";

.gallery-page-wrap {
    .api-loading {
        text-align: center;
        font-weight: bold;
        padding: 5vh 0;
    }
    .upload-own-wrap-container {
        max-width: 1440px;
        box-sizing: border-box;
        padding: 20px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        display: block;

        .upload-own-wrap {
        }

        .upload-loading-container {
            .upload-loading {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .loading-icon {
                    max-width: 100px;
                    margin-bottom: 2vh;
                    animation: boatrocking 3s infinite;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            h3 {
                margin-top: 2vh;
            }
            @keyframes boatrocking {
                0% {
                    transform: rotate(3deg);
                }
                50% {
                    transform: rotate(-10deg);
                }
                100% {
                    transform: rotate(3deg);
                }
            }
        }

        .hidden-input-wrap {
            position: absolute;
            opacity: 0;
            left: -20000px;
            top: -20000px;
        }
        h2 {
        }
        h3 {
            font-size: 1.2rem;
            font-weight: bold;
            color: #666;
            margin: 1vh 0;
        }
        .upload-own-button {
            margin-top: 2vh;
            display: inline-block;
            background: $primary-color;
            color: #fff;
            padding: 1vh 2vh;
            text-align: center;
            border-radius: 5px;
            font-size: 1.5rem;
            font-weight: bolder;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover {
                background-color: rgba($color: #00a6f5, $alpha: 0.1);
                color: #00a6f5;
            }
        }
        .discard-button {
            color: rgb(255, 0, 0);
            font-style: italic;
            font-size: 1rem;
            margin-top: 10px;
            cursor: pointer;
        }
        .empty-video-src {
            height:10vh;
            background:#000;
            aspect-ratio: 16/9;
            color:#fff;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .preview-post {
            margin: 0 auto;
            margin-top: 5vh;
            display: flex;
            max-width: 900px;
            @media (max-width: $mobile) {
                flex-direction: column;
            }
            .preview-left {
                justify-content: center;
                align-items: center;
                img {
                    margin: 0;
                    display: block;
                    @media (max-width: $mobile) {
                        width: 100%;
                    }
                }
            }
            .preview-right {
                flex: 1;
                padding-left: 20px;
                @media (max-width: $mobile) {
                    padding-left: 0;
                    margin-top: 2vh;
                }
                text-align: left;
                .preview-heading {
                }
                .preview-textarea-wrap {
                    display: flex;
                    margin-top: 2vh;
                    textarea {
                        flex: 1;
                        padding: 10px;
                        font-size: 1.1rem;
                        outline: none;
                        border: 1px solid #ccc;
                        height: 100px;
                        resize: none;
                        font-family: Arial, Helvetica, sans-serif;
                    }
                }
                .final-submit-button-wrap {
                    .final-submit-button {
                        margin-top: 2vh;
                        display: inline-block;
                        background: $primary-color;
                        color: #fff;
                        padding: 1vh 2vh;
                        text-align: center;
                        border-radius: 5px;
                        font-size: 1.5rem;
                        font-weight: bolder;
                        text-transform: uppercase;
                        font-weight: 700;
                        cursor: pointer;
                        transition: all 0.1s ease-in-out;
                        &:hover {
                            background-color: rgba($color: #00a6f5, $alpha: 0.1);
                            color: #00a6f5;
                        }
                        @media (max-width: $mobile) {
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }
    .gallery-wrap {
        column-count: 4; /* Adjust this to change the number of columns */
        @media (max-width: $mobile) {
            column-count: 1;
        }
        column-gap: 1vw;
        margin: 0 auto;
        padding: 20px;
        margin-top: 5vh;
        box-sizing: border-box;

        .gallery-item {
            break-inside: avoid; /* Prevents the item from being split across columns */
            margin-bottom: 1vw;
            .gallery-video {
                --seek-backward-button: none;
                --seek-forward-button: none;
            }
        }

        .gallery-image {
            background-color: #f7f7f7;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            position: relative;
            transition: all 0.1s ease-in-out;
            cursor: pointer;
            width: 100%;

            img {
                width: 100%;
                display: block;
                aspect-ratio: 1/1; /* Consider setting this dynamically based on the image or removing it if all images are the same aspect ratio */
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .processing-video-wrap {
            height:20vh;
            background:#efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding:20px;
            font-size:1rem;
            font-weight:bold;
            animation: boxPulse 2s infinite;
        }
        @keyframes boxPulse {
            0% {
                transform: scale(0.9);
            }
            50% {
                transform: scale(1);
            }
            100% {
                transform: scale(0.9);
            }
        }

        .gallery-comment {
            text-align: center;
            padding-top: 1vh;
            font-size: 1rem;
            font-style: italic;
            color: #333;
            @media (max-width: $mobile) {
                margin-bottom:2vh;
            }
        }
    }
}
