@import "../../../helpers/global.scss";

.home-hero {
    height: 100vh;
    position: relative;
    display: flex;
    @media (max-width: $mobile) {
        display:block;
    }
    .video-container {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }
    .transparent-overlay {
        background: url(../../../../public/images/hero-trans.png);

        background-size: 6px;
        height: 100%;
        position: absolute;
        width: 100%;
        opacity: 0.2;
        left: 0;
        top: 0;
        z-index: 1;
    }
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #00a6f5, $alpha: 0.4);
    }

    .hero-content {
        flex: 1;
        z-index: 10;
        position: relative;
        display: flex;
        @media (max-width: $mobile) {
            display:flex;
            flex-direction:column;
            height:100vh;
            background-color: rgba($color: #fff, $alpha: 0.1);
        }
        .hero-left {
            display: flex;
            flex-direction: column;

            width: 30vw;
            
            background-color: rgba($color: #fff, $alpha: 0.1);
            @media (max-width: $mobile) {
                width:auto;
                padding:20px;
                background:none;
            }
            .logo-wrap {
                position: relative;
                z-index: 99999;
                .welcome {
                    padding-left: 5vw;
                    position: relative;
                    top: 4vh;
                    color: #fff;
                    font-weight: bolder;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    font-weight: 700;
                  
                    @media (max-width: $mobile) {
                        top:2vh;
                    }
                }
                .logo {
                   
                    width: 105%;
                    @media (max-width: $mobile) {
                        width:100%;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            .underlogo-block {
                flex: 1;
                flex-direction: column;
                display: flex;
                margin-bottom: 15vh;

                @media (max-width: $mobile) {
                   margin-bottom:0;
                }

                .product-3d {
                    flex: 1;
                    display: flex;
                    .model-wrapper {
                        flex:1;
                        @media (max-width: $mobile) {
                            position:absolute;
                            left:0;
                            top:0;
                            width: 100%;
                  
                            height: 100vh;
                         }
                    }
                }
                .tagline {
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-weight: bolder;
                    font-size: 1.3vw;
                    text-align: center;
                    @media (max-width: $mobile) {
                        position: relative;
                        z-index: 9999;
                        font-size: 3.5vw;
                    }
                }
            }
        }
        .hero-right {
            flex: 1;
            padding-left: 5vw;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            .large-descriptor {
                font-size: 2.7vw;
                text-align: center;
                padding-top: 10vh;
                transform: scale(1, 1.5);
                color: #fff;
                text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
                font-weight: 800;
                @media (max-width: $mobile) {
                    font-size: 5vw;
                    padding-top: 0;
                    transform: scale(1, 1);
                    display:none;
                }
            }
            .main-description {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                margin-bottom: 15vh;

                flex-direction: column;
                padding-left:5vw;
                color: #fff;
                font-weight: bold;
                font-weight: 700;
                @media (max-width: $mobile) {
                    padding-left:0;
                    
                    
                }
                .text {
                    display: flex;
                    font-size: 2vw;
                    text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
                    flex-direction: column;
                    &:is(.col) {
                        flex-direction: column;
                    }
                    &:is(.row) {
                        flex-direction: row;
                    }
                    &:is(.first) {
                        font-size: 2.1vw;
                        @media (max-width: $mobile) {
                            font-size: 4.5vw;
                        }
                        .never-lose-it {
                            padding-right:2vw;
                        }
                    }
                    &:is(.second) {
                        margin: 3vh 0;
                        font-size:5.7vw;
                        padding-left:2vw;
                        @media (max-width: $mobile) {
                            font-size: 10vw;
                        }
                        span {
                            color:#00c0fb;
                            font-weight: 1000;
                        }
                        .snap-to-store {
                            padding-left:2vw;
                        }
                    }
                    &:is(.third) { 
                        padding-left:2vw;
                        @media (max-width: $mobile) {
                            font-size: 4.5vw;
                        }
                    }
                }
            }
        }
    }

    .hero-wave {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 15vh;
        z-index: 111;
        background: url("../../../../public/images/wave.png");
        background-position: top center;
        background-size: 100% 100%;
        @media (max-width: $mobile) {
            background-size:cover;
        }
    }
    .scroll-down-wrap {
        position: absolute;
        z-index: 9999;
        bottom:0;
        left:0;
        width:100%;
        margin-bottom:18vh;
        display: flex;
        opacity:0.7;
        pointer-events: none;
        justify-content: center;
        box-sizing: border-box;
        @media (max-width: $mobile) {
            margin-bottom:16vh;
        }
        img {
            transition: all 0.2s ease-in-out;
            animation: upDown 1s ease-in-out infinite;
            @media (max-width: $mobile) {
                height:20px;
            }
        }

        @keyframes upDown {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(10px);
            }
            100% {
                transform: translateY(0);
            } 
        }
    }
}
