@import "../../../helpers/global.scss";


.gallery-modal-wrap {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba($color: #00a6f5, $alpha: 0.5);
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;;
    .gallery-modal {
        padding:10px;
        margin:10px;
        background:#fff;
        display: flex;
        justify-content: center;
        position: relative;
        flex-direction: column;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        min-width:40vh;
        min-height:20vh;
        max-width:100%;
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.5);
        img {
            max-height:80vh;
            max-width:100vw;
            @media (max-width: $mobile) {
                max-height:100%;
                max-width: 100%;
            }
        }
        .loading {
            width:30vw;
            height:30vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .gallery-modal-close {
            position:absolute;
            right:-25px;
            top:-25px;
            background:50px;
            display:flex;
            justify-content: center;
            align-items: center;
            color:#fff;
            font-weight: bold;
            width:50px;
            height:50px;
            border:5px solid #fff;
            cursor: pointer;
            border-radius: 100px;
            text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.5);
            background:#00a6f5;
            @media (max-width: $mobile) {
                right:0px;
            }
        }
    }
}