.white-body-template {
    background:white;
    position: relative;

    z-index: 9999999;
    min-height:80vh;

    h1 {
        margin:0;
        padding:0;
    }
}