@import "../../../helpers/global.scss";

.slide-indi-container {
    display: flex;
    flex:1;
    width:100%;
    height:100%;
    flex-direction: column;
    left:0;
    position: absolute;

    top:0;
}
#txt-slide-0 {
    position: absolute;
}
