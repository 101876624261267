@import "../../../helpers/global.scss";

.page-banner {
    background:rgba($color: #00a6f5, $alpha: 0.2);
    background-size:cover;
    aspect-ratio: 640/159;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    @media (max-width: $mobile) {
        aspect-ratio: initial;
        height:40vh;
    }
    h1 {
        font-size:4rem;
        text-transform: uppercase;
        font-weight: bold;
        color:#fff;
        transform: scale(1, 1.2);
        margin-bottom:5%;
        text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        b {
            color:#00a6f5;
        }
         @media (max-width: $mobile) {
        font-size:3rem;
         margin-bottom:15%;
    }
    }
}