@import "../../../helpers/global.scss";

.header-container {
    display:flex;
    width:100%;
    position: relative;
    top:-80px;
    z-index: 99999999;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: $mobile) {
       display:none;
    }

    .header-left {
        width:20vh;
        display: flex;
        padding-left:5vh;
        .logo {
            img {
                max-width: 100%;
            }
        }
    }
    .header-center {
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
       
       
        nav {
            ul {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                list-style-type: none;
                li {
                    margin:0px 0.5vw;
                    
                    padding: 1vh 2vh;
                    text-align: center;
                    border-radius: 5px;
                    font-size: 1.2rem;
                    font-weight: bolder;
                    text-transform: uppercase;
                    font-weight: 700;
                    cursor: pointer;
                    transition: all 0.1s ease-in-out;
                    &:hover {
                        background-color: rgba($color: #00a6f5, $alpha: 0.1);
                    color: #00a6f5;
                    }
                }
            }
        }
        .active {
            background-color: rgba($color: #00a6f5, $alpha: 0.1);
            color: #00a6f5;
        }
    }
    .header-right {
        width:20vh;
        display: flex;
        justify-content: flex-end;
        padding-right:5vh;
        .buy-now-button {
            background-color: #00a6f5;
            color: #fff;
            text-decoration: none;
            padding: 1vh 2vh;
            border-radius: 5px;
            font-size: 1.2rem;
            font-weight: bolder;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: rgba($color: #00a6f5, $alpha: 0.1);
                color: #00a6f5;
            }
        }
    }
}

.komfort-stik-bar-header {
    padding-top:2vh;
    @media (max-width: $mobile) {
        padding:2vh 5vw;
        text-align: center;
        font-weight: bold;
        
    }
    img {
        max-width:100%;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
    }
    .upsercase {
        text-transform: uppercase   ;
        margin-bottom:1vh;
    }
}