.store-not-ready-content-wrap {
    min-height:30vh;
    padding:5vw;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    .made-in-america {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:2vh;
        img {
            width: 30px;
           display: block;
        }
        span {
            font-size: 1.2rem;
            margin:0 10px;
            
        }
    }
    .big-buy-now {
        background-color: #00a6f5;
        color: #fff;
        text-decoration: none;
        padding: 2vh 4vh;
        border-radius: 5px;
        font-size: 3rem;
        margin-top:5vh;
        font-weight: bolder;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: rgba($color: #00a6f5, $alpha: 0.1);
            color: #00a6f5;
        }
    }
}