@import "../../helpers/global.scss";

.installation-content-wrap {
    .install-video-details {

        margin:0 auto;
        max-width:90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .install-video-wrap {
        margin-top:10vh;
     
        display: flex;
        justify-content: center;
        text-align: center;
        gap:10vw;
        align-items: center;
        iframe {
            width:50vw;
            aspect-ratio: 16/9;
     
            @media (max-width: $mobile) {
                width:100%;
            }
        }
        @media (max-width: $mobile) {
            display: block;
            
        }
        .installation-image {
          
            width:30vw;
            max-width:300px;
            @media (max-width: $mobile) {
                text-align: center;
                margin:0 auto;
                max-width: auto;
                padding-bottom:10vh;
                width:100%;
             }
            img {
                display: inline-block;
                width:100%;
            }
        }
    }
    .large-installation-image {
        margin-top:10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
        img {
            width:100%;
            max-width:1200px;
        }
    }
}