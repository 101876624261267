@import "../../helpers/global.scss";

.contact-banner-wrap {
  b {
    color: #fff !important;
  }
  margin-bottom: 5vh;
}

.contact-content-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: $mobile) {
    padding: 20px;
  }
  .contact-info-wrap {
    .text {
      text-align: center;
      h1 {
      }
      p {
      }
    }
  }

  .contact-forms-wrap {
    margin: 0 auto;
    display: flex;
    padding: 20px;
    width: 100vw;
    max-width: 1300px;

    a {
      color:#00a6f5;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      padding: 0px;
      width: 100%;
      margin: 0px;
      border-top: none;
      margin-top: 0px;
      padding-top: 0px;
    }
    .primary-contact-form {
      width: 100%;
      @media (max-width: $mobile) {
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 5vh;
      }
      h1 {
        margin-bottom: 5vh;
      }
      .errors-wrap {
        margin-bottom: 5vh;
        .error-message {
          color: red;
          font-weight: bold;
        }
      }
      .contact-form {
        .form-row {
          display: flex;
          justify-content: space-between;
          @media (max-width: $mobile) {
            flex-direction: column;
          }
          .form-cell {
            display: table-cell;
            box-sizing: border-box;
            flex: 1;
            padding-bottom: 3vh;
            &:nth-child(2) {
              padding-left: 20px;
              @media (max-width: $mobile) {
                padding-left: 0px;
              }
            }
            label {
              display: block;
              margin-bottom: 2vh;
            }

            input,
            textarea {
              box-sizing: border-box;
              width: 100%;
              font-size: 1.5rem;
              padding: 10px;
              outline: none;
              font-family: Arial, Helvetica, sans-serif;
              border: 1px solid #efefef;
              border-radius: 10px;
            }
            textarea {
              min-height: 150px;
              resize: vertical;
            }
            &.full-row .form-cell {
              flex: 2; // take up twice as much space as other cells
            }
          }

          .submit button {
            padding: 1vh 2vh;
            text-align: center;
            border-radius: 5px;
            font-size: 1.2rem;
            font-weight: bolder;
            border: none;
            text-transform: uppercase;
            outline: none;
            font-weight: 700;
            cursor: pointer;
            background-color: rgba($color: #00a6f5, $alpha: 1);
            color: #fff;
            transition: all 0.1s ease-in-out;
            &:hover {
              background-color: rgba($color: #00a6f5, $alpha: 0.1);
              color: #00a6f5;
            }
            @media (max-width: $mobile) {
                width: 100%;
              }
          }
        }
      }
    }
    .gallery-form-wrap {
      padding-left: 5vw;
      @media (max-width: $mobile) {
        padding-left: 0px;
      }
      h1 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
      p {
        line-height: 2rem;
      }
      .gallery-image {
        margin-bottom: 3vh;
        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
        }
      }
      .phone {
        font-weight: bold;
        font-size: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        background: #efefef;
        padding: 1vw;
        text-align: center;
      }
      .email {
        font-weight: bold;
        font-size: 1.5rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        background: #efefef;
        padding: 1vw;
        text-align: center;
      }
    }
  }

  .additional-info {
    padding-bottom:5vh;
  }
}
