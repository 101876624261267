.admin-content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0px 5vw;
    padding-top:5vh;
  
    h1 {
        font-size:3rem;
    }
    
    .admin-login-form {
        max-width:300px;
        margin-top:5vh;
        display: flex;
        gap: 10px;
        flex-direction: column;
        width:100%;
        input {
            padding:5px;
            font-size:1.3rem;
        }
        button {
            display: inline-block;
          background-color: #00a6f5;
          color: #fff;
          text-decoration: none;
          padding:1vh 2vh;
          outline:none;
          border:0;
          border-radius: 5px;
          font-size: 1.5rem;
          font-weight: bolder;
          text-transform: uppercase;
          font-weight: 700;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: rgba($color: #00a6f5, $alpha: 0.1);
            color: #00a6f5;
          }
        }
    }
}