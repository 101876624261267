@import "../../../helpers/global.scss";

.mobile-header {
  position: sticky;
  top: 0;
  background: #fff;
  display: flex;
  z-index: 99999;
  padding: 2vw;
  box-sizing: border-box;
  border-bottom: 5px solid #efefef;
  align-items: center;
  @media (min-width: $mobile) {
    display: none;
  }

  .mobile-logo {
    img {
      width: 40vw;
    }
  }
  .mobile-menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .active {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #00a6f5, $alpha: 0.1);
      border: 1px solid rgba($color: #00a6f5, $alpha: 0.1);
      padding: 3vw 3vw;
      transition: all 0.1s ease-in-out;
      color: #00a6f5;
      span {
        position: relative;
        top: 1px;
        text-align: center;
        border-radius: 5px;
        font-size: 1.2rem;
        font-weight: bolder;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
        &:hover {
        }
      }
      img {
        display: block;
        padding-left: 2vw;
        max-height: 3vh;
      }
    }
  }
}

.large-mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 99;
  padding: 5vw;
  box-sizing: border-box;
  .close {
    text-align: right;
    border-bottom: 1px solid #efefef;
    padding: 5vw;
    font-weight: bold;
    color: #00a6f5;
  }
  .items {
    margin-top: 5vw;

    nav {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          font-size: 2rem;
         
          font-weight: bold;
          padding: 5vw;
          span {
            display: block;
            transform: scale(1, 1);
          }
        }
        .active {
          background-color: rgba($color: #00a6f5, $alpha: 0.1);
          border: 1px solid rgba($color: #00a6f5, $alpha: 0.1);
          font-weight: bold;
          color: #00a6f5;
          padding: 3vw 3vw;
          border-radius: 5px;
        }
      }
    }
  }
}

.mobile-komfort-stik-header {
  @media (min-width: $mobile){
    display: none;
  }
}