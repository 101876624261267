@import "../../../helpers/global.scss";

.footer-wrap {
    margin-top:15vh;
    padding-top:20vh;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #00a6f5;
    background-image: url('../../../../public/images/footer.jpg');
    background-size:cover;
    background-position: center top;
    text-shadow: 0px 0px 15px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    padding-bottom:5vh;
    justify-content: center;
    .sizer {
        max-width: 1000px;
        .footer {
            display: flex;
            color:#fff;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media (max-width: $mobile) {
                font-size: 1rem;
                padding:20px;
            }
            a {
                color:#fff;
                text-decoration: none;
            }
            flex-direction: column;
            .footer-logo {
                max-width:500px;
                img {
                    max-width: 100%;
                }
            }
            h2 {
                @media (max-width: $mobile) {
                    font-size: 1.5rem;
                }
            }

            .legal {
                margin-top:2vh;
                font-size:0.8rem;
            }

            .hit-counter-wrap {
                display: flex;
                justify-content: center;
                margin-top:40px;
                align-items: center;
                flex-direction: column;
                .hit-counter {
                    aspect-ratio: 161/44;
                    // background:url('../../../../public/images/counter-bg.jpg');
                    // background-size:100% 100%;
                    background-color: rgba($color: #fff, $alpha: 0.2);
                    max-width:200px;
                    width:100vw;
                    font-weight: bold;
                    display: flex;
                    color:#fff;
                    box-shadow: 0px 0px 20px rgba(#000, 0.3);
                    justify-content: center;
                    align-items: center;
                    font-size:2.1rem;
                    font-family: monospace;
                }
                .hit-desc {
                    margin-top:10px;
                    font-family: monospace;
                }
            }
            .installation-hits {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:2vh;
                font-family: monospace;
                .installation-hits-count {
                    padding:5px;
                    margin-left:10px;
                    background:rgba($color: #000000, $alpha: 0.2)

                }
            }
            .admin-login-area {
                margin-top:2vh;
                font-size:1rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}