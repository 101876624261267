$blue-color: #00a6f5;
@import "../../helpers/global.scss";

.competetive-edge-wrap {
  margin: 0 auto;
  min-height: 70vh;

  display: flex;
  padding-top: 5vh;
  justify-content: center;
  background-image: url("../../../public/images/competitive-edge-background.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $mobile) {
    flex-direction: column;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
  }
  .container {
    width: 70vw;
    display: flex;
    align-items: center;
    @media (max-width: $mobile) {
      flex-direction: column;
      width: 100vw;
    }
    .large-image {
      width: 50vw;
      @media (max-width: $mobile) {
        width: 100vw;
      }
      img {
        width: 100%;
        max-width: 100%;
        @media (max-width: $mobile) {
          width: 100vw;
          max-width: none;
        }
      }
    }
    .content {
      padding-left: 5vw;
        @media (max-width: $mobile) {
            margin-top:5vh;
        }
      h1 {
        font-size: 3rem;
        font-weight: 500;
        b {
          display: block;
          color: $blue-color;
        }
      }
      p {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: bold;
      }
    }
  }
}

.patent-pending-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
  padding-top:10vh;
    @media (max-width: $mobile) {
        padding-left:20px;
        padding-right:20px;
    }
  img {
    max-width: 100%;
  }
}

.designed-by-fisherman-wrap {
  background: #cdf3fe;
  display: table;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
  }
  .content {
    display: table-cell;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    h1 {
      font-size: 2.5rem;
      color: $blue-color;
    }
    p {
      font-weight: bold;
      line-height: 2rem;
    }
    .seal-of-approval {
      text-align: right;
   
      @media (max-width: $mobile) {
        text-align: center;
      }
      img {
        width:20vw;
        display: inline-block;
      }
    }
  }
  .large-image {
    display: table-cell;
    background: url("../../../public/images/designed-by-hiddent.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:#99d8e9;

    width: 50vw;
  }
}

.hiddent-advantages-wrap {
  padding-bottom: 10vh;
  z-index: 1;
  position: relative;
  .long-heading {
    padding: 10vh;
    text-align: center;
    font-size: 1.8rem;
    padding-bottom: 10vh;
    font-weight: 300;
    @media (max-width: $mobile) {
        padding: 5vh;
        font-size: 1.5rem;
    }
  }

  .content-block {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .content-sizer {
      margin: 0 auto;
      display: flex;
      max-width: 80vw;
      @media (max-width: $mobile) {
        flex-direction: column;
        max-width: 100vw;
        img {
            margin:0 auto;
            display: block;
            max-width:90%;
        }
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.5rem;
      position: relative;
      z-index: 999;
      line-height: 2.5rem;
      padding-left: 5vw;
      .text-limit {
        max-width: 500px;
        @media (max-width: $mobile) {
            padding:20px;
            text-align: center;
        }
      }
    }
    .large-image {
      position: relative;
   
      overflow: hidden;
      z-index: 999;
      @media (max-width: $mobile) { 
        order: 4;
      }
    }
    &.image-left {
      background-image: url("../../../public/images/competitive-edge-background.png");
    }
    &.image-right {
       
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../../../public/images/competitive-edge-background.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
        transform: scaleY(-1);
        /* Prefixed versions for better browser compatibility */
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
      }
      .content {
        padding-left: 0;
        padding-right: 5vw;
      }
    }
  }
}

.retro-fit-wrap {
  background: url("../../../public/images/rods.jpg");
  background-size: cover;
  height: 100vh;
  max-height: 80vh;
  margin-bottom: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    max-width: 1000px;
    text-align: center;
    padding: 0px 5vw;
    color: #fff;
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
      @media (max-width: $mobile) {
        font-size:2rem;
        line-height: 2.5rem;
      }
    }
    p {
      font-size: 1.5rem;
      line-height: 2.5rem;
      @media (max-width: $mobile) {
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }
  }
}

.available-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  .sizer {
    display: flex;
    width: 70vw;
    @media (max-width: $mobile) {
        width:100vw;
        box-sizing: border-box;
        padding:20px;
        flex-direction: column;
    }
    .content {
      padding-right: 5vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: $mobile){
        text-align: center;
      }
      h1 {
        font-size: 3rem;
        font-weight: 500;
        b {
          display: block;
          color: $blue-color;
        }
      }
      p {
        line-height: 2rem;
      }
      .buynow-button {
        a {
          display: inline-block;
          background-color: #00a6f5;
          color: #fff;
          text-decoration: none;
          padding: 2vh 5vh;
          border-radius: 5px;
          font-size: 1.5rem;
          font-weight: bolder;
          text-transform: uppercase;
          font-weight: 700;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: rgba($color: #00a6f5, $alpha: 0.1);
            color: #00a6f5;
          }
        }
        @media (max-width: $mobile)
        {
            margin-top:2vh;
        }
      }
    }
    .large-image {
        @media (max-width: $mobile) {
            margin-top:10vh;
            img {
                width: 100%;
            }
        }
      img {
        max-height: 80vh;
      }
    }
  }
}

.showoff-video {
  margin-top:10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: $mobile) {
    flex-direction: column;
    padding:0 !important;
  }
  .video {
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $mobile) {
      flex-direction: column;
      padding:0 !important;
    }
    
    &:first-child {
      padding-right: 5vw;
    }
    &:last-child {
      padding-left: 5vw;
    }
    iframe{
      

      height:80vh;
      aspect-ratio: 9/16;
      
    }
  }
}

.large-showoff {
  margin-top:5vh;
  display: flex;

  @media (max-width: $mobile) {
    flex-direction: column;
    padding:0 !important;
  }
  .video {
    padding:5vw;
    padding-top:0;
    padding-bottom:0;
    width:100vw;


    div {
      width:100%;
      height:80vh;
  
    }

    iframe{
      width:100%;
      height:100%;
   
      
    }
  }
}




.komfort-stik-topmost {
  position: relative;
  z-index: 9999;
  padding:10px;
  background:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  .komfort-small-logo {
    height:20px;
    margin:0px 10px;
  }
  .america {
    height:20px;
    margin-left:10px;
  }
}