@import "../../../../helpers/global.scss";

#slide3 {
    display: flex;
    .three-col {
        display: flex;
        padding-right:5vw;
        @media (max-width: $mobile) {
            flex-direction: column;
            padding-right:0;
        }
            

    .colpic {
       
        .img-container {
            max-width:12vw;
            padding:20px;
            border-radius: 10px;
        background:#fff;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,1);
        @media (max-width: $mobile) {
            max-width: 30vw;
        }
        }
        .roLeft {
            transform: rotate(-10deg);
            @media (max-width: $mobile) {
                transform: rotate(0deg);

                margin:0 auto;
                margin-bottom:5vh;
                img {
                    max-height:30vh;
                }
            }
           
        }
        .roRight {
            transform: rotate(10deg);
           

        }
        img {
            display: block;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
            max-width:100%;
        }

        &.leftpic {
          
            @media (max-width: $mobile) {
                display:none;
                order: 5;
            }
        }


    }
    .coltext {
        flex:1;
        display: flex;
        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        transform: scale(1, 1.5);
        text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
        font-size:1rem;
        padding:0px 2vw;
        text-transform: uppercase;
        @media (max-width: $mobile) {
            transform: scale(1, 1.5);
            padding:0px 5vw;
            font-size:0.7rem;
            order:3;
        }
        h1 {
            
        }
        h2 {
            margin:0;
            padding:0;
        }
    }
}
}


.slide1-full {
    position: fixed;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    z-index: 999999999;
    display: flex;
    flex-direction: column;
    background: url('../../../../../public/images/map-bg.jpg');
    background-size:cover;
    background-position: left center;
    background-color: rgba($color: #00a6f5, $alpha: 0.99);
    // .big-logo {
    //     padding-bottom:10vh;
    //     img {
    //         width: 150%;
            
    //     }
    // }
        .text-top {
            margin-top:15vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .large-logo {
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width:50vw;
                @media (max-width: $mobile) {
                    width: 100vw;
                }
            }
        }
        .text-bottom {
            margin-bottom:25vh;
            display: flex;
            justify-content: center;
            align-items: center;

        }
        .bigtext {
            color:#fff;
            text-transform: uppercase;
            font-weight: 800;
            font-size:4rem;
            font-style: italic;
            text-shadow: 0px 0px 10px rgba(0,0,0,0.5);
            transform: scale(1, 1.5);
            @media (max-width: $mobile) {
                font-size:2rem;
                text-align: center;
            }
        }
}