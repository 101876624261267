@import "../../helpers/global.scss";
#komfort-stik-overall-wrap {
   
    #komfort-stik-introducing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding:0px 5vw;
      padding-top:5vh;
      h3 {
        margin:0;
      }
      h1 {
        font-size:5rem;
      }
      .komfort-desc {
        margin-top:5vh;
        max-width:1200px;
        line-height: 1.8rem;
        font-size:1.4rem;
      }
      img {
        max-width: 100%;
      }
    }
  
    .komfort-stik-indi {
   
      margin:0 auto;
      text-align: center;
      margin-top:10vh;
      
  
      .komfort-title {
        font-weight: bold;
        font-size: 2rem;
        padding-bottom:2vh;
        margin:0 auto;
        max-width:1200px;
         img {
            max-width: 100%;
         }
      }
      .komfort-images {
    
        max-width:1500px;
        margin:0 auto;
        background:#efefef;
        img {
            max-width: 100%;
        }
  
      }

      
      .komfort-desc {
        padding-top:2vh;
        line-height: 1.8rem;
        font-size:1.4rem;
        max-width:1200px;
        margin:0 auto;
        @media (max-width: $mobile){
            padding:5vw;
        }
        .learnmore-button {
          padding-top:2vh;
          span {
            display: inline-block;
            background-color: #00a6f5;
            color: #fff;
            text-decoration: none;
            padding: 2vh 5vh;
            border-radius: 5px;
            font-size: 1.5rem;
            font-weight: bolder;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              background-color: rgba($color: #00a6f5, $alpha: 0.1);
              color: #00a6f5;
            }
          }
        }
      }
    }
  }

  .made-in-america {
    padding-top:5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        max-width: 100%;
    }
  }