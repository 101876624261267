@import "../../../helpers/global.scss";

.bio-container {
    margin-top:10vh;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    padding:20px;
   
    align-items: center;
    .bio-wrap {
        max-width:1500px;
   
        display: flex;
        justify-content: start;
        flex:1;
  
        align-items: center;
        box-sizing: border-box;

        @media (max-width: $mobile) {
            flex-direction: column;
            max-width:100vw;
            padding:0;
        }
        .bio-image {
            width:15vw;
           align-self: flex-start;
           @media (max-width: $mobile) {
            margin-bottom:5vh;
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width:90vw;
           
     
        }
            img {
                width:100%;
                box-sizing: border-box;
                padding:20px;
                background:#fff;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,1);
                @media (max-width: $mobile) {
                    
                    max-width: 50vw;
                }
            }
        }
        .bio-content {
            padding-left:5vw;
            line-height:2.5rem;
            flex:1;
           
            h1 {
                transform: scale(1, 1.2);
                font-size:2.5rem;
                @media (max-width: $mobile) {
                    font-size:2rem;
                }
            }
            h2 {
                color:#000;
                font-size:1.1rem;
                font-weight: 500;
                @media (max-width: $mobile) {
                    font-size:1.5rem;
                }
            }
            p {

            }
           
            .readmore-button span {
                font-weight: bold;
                padding:10px 20px;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
                background-color: rgba($color: #00a6f5, $alpha: 1);
                    color: #fff;
                font-style: italic;
                &:hover{
                    background-color: rgba($color: #00a6f5, $alpha: 0.1);
                    color: #00a6f5;
                }
            }
        }
        .readmore-container {
            transition: all 0.2s ease-in-out;
            overflow: hidden;
            height:0;
          
        }
    }
}